import React from 'react'

const Book = (props) => {
  
    const stylez = {
        color: '#617d98',
        fontSize: '0.75rem',
        marginTop: '0.5rem',
      }

    const indexStyle = {
        margin:'0',
        float:'left',
        background: 'rgba(0,0,0,0.2)',
        position: 'absolute',
        top: '0',
        left: '0',
        borderTopLeftRadius: '25%',
        borderBottomRightRadius: '25%'        
    }  
      const { img, title, author, children, id, number} = props
      console.log(props)
     
    
      return (
        <article className="book">
          <p style={indexStyle}>{`# ${number+1}`}</p>
          <img src={img} alt="yeee" />
          <h2>{title.toUpperCase()}</h2>
          <h4 style={stylez}>{author}</h4>
           {children}
        </article>
      )
  
}

export default Book



import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import books from './books'
import Book from './Book'



const BookList = () => {
 
  
  return (
    <React.Fragment>
      <h1 style={{textAlign:'center', padding:'50px', paddingBottom:'0'}}>AMAZONS BEST SELLERS</h1>
    <section className="bookList">
      <EventExamples />
      {books.map((book, index) => {
        return <Book {...book} key={book.id} number={index}/>
      })}
    </section>
    </React.Fragment>
  )
}

const EventExamples = () => {
  
  const formSumbit = (e) => {
    e.preventDefault()
    console.log('sumbitteeedd')
  }

  return (
    <section>
      <form onSubmit={formSumbit}>
        <h2>typical formmmm</h2>
        <input
          type="text"
          name="nameke"
          style={{ margin: '1rem 0' }}
          onChange={(e) => {
            console.log(e.target.value)
          }}
        ></input>
        <button type="sumbit">click here</button>
        <button
          onClick={() => {
            console.log('hello world')
          }}
        >
          hello world
        </button>
      </form>
    </section>
  )
}



// function Greeting(){
//     return React.createElement('h2', {}, 'hellao world')
// }

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(<BookList />)

import img1 from './images/book-1.jpg'
import img2 from './images/book-2.jpg'
import img3 from './images/book-3.jpg'

const books = [
    {
      author: 'Jordan Moore',
      title: 'Interesting Facts For Curious Minds',
      img: img1,
      id: 1,
    },
    {
      author: 'James Clear',
      title: 'Atomic Habits',
      img: img2,
      id: 2,
    },{
        author: 'Deny S',
        title: 'Ufos & Aliens',
        img: img3,
         id: 3,
      },
  ]

  export default books;

